<template>
    <v-snackbar elevation="24" v-model="showNotification" :color="isOnline ? 'success' : 'error'" top>
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          v-if="!isOnline"
          color="#ffffff"
          text
          v-bind="attrs"
          @click="showNotification = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </template>
  
  <script>
  export default {
    props: {
        isOnline: {
            type: Boolean,
            default: false,
        },
    },
    data() {
      return {
        showNotification: false,
        message: "",
      };
    },
    watch: {
      isOnline(newStatus) {
        this.showNotification = true;
        this.message = newStatus ? "You are back online!" : "You are offline. Please check your internet connection.";
        setTimeout(() => {
            if(this.message === 'You are back online!'){
              this.showNotification = false;
            }
        }, 5000);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Optional styling */
  </style>
  