<template>
<div>
    <v-autocomplete :search-input.sync="search" :disabled="!packagesList.length" v-model="dropdownSelectedTags" multiple elevation="0" :items="packagesList" label="Select Applicable Package" item-text="sub_package_id" item-value="sub_package_uuid" small-chips solo class="cus-autocomplete" @change="getSelectedTags()">
        <template v-slot:prepend-item>
            <v-list-item ripple @mousedown.prevent @click="toggle">
                <v-list-item-action>
                    <v-icon :color="dropdownSelectedTags.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                    </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        Select All
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:selection="{ index }">
            <v-chip small v-if="dropdownSelectedTags.length == 1">{{ index + 1}} package selected</v-chip>
            <v-chip small v-if="index == (dropdownSelectedTags.length - 1) && dropdownSelectedTags.length > 1">{{ index + 1}}
                packages selected</v-chip>
        </template>
        <template v-slot:item="{ props, item }">
            <v-list-item class="d-flex">
                <div>
                    <v-simple-checkbox color="primary" :value="isSelected(item)" @click="toggleItem(item)" />
                </div>
                <div class="font-weight-bold mb-0 mb-0">
                    {{ item.sub_package_id }} - {{ item.sub_package_name }}
                </div>
            </v-list-item>
        </template>
    </v-autocomplete>
</div>
</template>

<script>
import axios from 'axios'

export default {
    props: ['taglists', 'currentTag', 'checkedTags', "authInfo"],
    data() {
        return {
            packagesList: [],
            dropdownSelectedTags: [],
            search: ''
        }
    },
    components: {},
    created() {},
    computed: {
        selectedAllTags() {
            return this.dropdownSelectedTags.length === this.packagesList.length
        },
        selectedSomeTags() {
            return this.dropdownSelectedTags.length > 0 && !this.selectedAllTags
        },
        icon() {
            if (this.selectedAllTags) return 'mdi-close-box'
            if (this.selectedSomeTags) return 'mdi-minus-box'
            return this.dropdownSelectedTags.length > 0 ? 'mdi-minus-box' : 'mdi-checkbox-blank-outline'
        },
    },
    watch: {
        checkedTags(to, from) {
            if (to.length != from.length) {
                this.customFilter();
            }
        }

    },
    mounted() {
        this.getSubPackageList()
    },
    methods: {
        getSubPackageList() {
            let url = ''
            if (this.$route.name == 'VendorEnqDetails' || this.$route.name == 'VendorDetails') {
                url = process.env.VUE_APP_API + '/package/sub_packages?token=' + this.authInfo.token + '&vendor_id=' + this.$route.query.vendor_id + '&package_id=' + this.$route.query.package_id + '&vendor_id=' + this.$route.query.vendor_id
            } else {
                url = `${process.env.VUE_APP_API}/package/sub_packages?package_id=${this.$route.query.package_id}`
            }
            axios({
                url: url,
                method: 'get'
            }).then((res) => {
                this.packagesList = res.data.sub_packages
                this.customFilter();
                const selectedTag = this.packagesList.find(tag => tag.sub_package_uuid === this.$props.currentTag?.sub_package_uuid);
                if (selectedTag) {
                    this.dropdownSelectedTags.push(selectedTag);
                }
                this.getSelectedTags()
            })
        },
        customFilter() {
            if (this.checkedTags) {
                this.dropdownSelectedTags = this.packagesList.filter(item => (this.checkedTags.includes(item.sub_package_uuid)));
                if (this.$route.name != 'Offer Listing') {
                    this.getSelectedTags();
                }
            }
        },
        isSelected(item) {
            return this.dropdownSelectedTags.some(tag => tag.sub_package_uuid === item.sub_package_uuid);
        },
        toggleItem(item) {
            if (this.dropdownSelectedTags.includes(item)) {
                this.dropdownSelectedTags = this.dropdownSelectedTags.filter(v => v !== item);
            } else {
                this.dropdownSelectedTags.push(item);
            }
            this.getSelectedTags()
        },
        toggle() {
            if (this.selectedAllTags) {
                this.dropdownSelectedTags = []
            } else {
                this.dropdownSelectedTags = this.packagesList.slice()
            }
            this.getSelectedTags()
        },
        getSelectedTags() {
            this.$emit('getSelectedTags', this.dropdownSelectedTags)
        }
    }
}
</script>

<style lang="scss">

  </style>
