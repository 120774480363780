<template>
    <v-btn title="Scroll To Top" class="mx-2 scroll-top" fab dark color="#bf360c" v-if="isScrolled" @click="scrollToTop"><v-icon>mdi-arrow-up-circle</v-icon></v-btn>
</template>
<script>

export default {
  data() {
    return {
      isScrolled: false
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 700; // Show button after 700px of scrolling
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}

</script>
<style lang="scss">

</style>
