<template>
    <div>
      <v-autocomplete :readonly="isLocked" :disabled="!offerList.length" @change="updateOffers()"
        v-model="selectedOffers" :multiple="selectionType" elevation="0" item-text="name" item-value="id"
        :items="offerList" label="Select Offer(s)" small-chips solo class="cus-autocomplete">
        <template v-slot:prepend-item v-if="selectionType">
          <v-list-item ripple @mousedown.prevent @click="toggle" v-if="selectionType">
            <v-list-item-action>
              <v-icon :color="selectedOffers.length > 0 ? 'indigo darken-4' : ''">
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Select All
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:selection="{ index }" v-if="selectionType">
          <v-chip small v-if="selectedOffers.length == 1">{{ index + 1 }} offers selected</v-chip>
          <v-chip small v-if="index == (selectedOffers.length - 1) && selectedOffers.length > 1">{{ index + 1 }}
            offers selected</v-chip>
        </template>
      </v-autocomplete>
    </div>
  </template>
  <script>
  export default {
    props: {
      defaultOffer: {
        type: Array,
        default: () => []
      },
      selectionType:{
        type:Boolean,
        default:true
      },
      isLocked:{
        type:Boolean,
        default:false
      },
    },
    data() {
      return {
        selectedOffers: [],
        offerList: []
      }
    },
    computed: {
        selectedAllOffers() {
            return this.selectedOffers.length === this.offerList.length
        },
        selectedSomeOffers() {
            return this.selectedOffers.length > 0 && !this.selectedOffers
        },
        icon() {
            if (this.selectedAllOffers) return 'mdi-close-box'
            if (this.selectedSomeOffers) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    watch: {
        defaultOffer (newVal) {
            this.selectedOffers = this.offerList.filter(item => newVal.find(x => x === item.id)).map(item => item.id);
            this.updateOffers();
            // this.offerList.forEach(item => {
            //     if(newVal.find(x => x === item.id)) {
            //         item['disabled'] = true;
            //     } else {
            //         item['disabled'] = false;
            //     }
            // })
        }
    },
    async mounted() {
        this.offerList = await this.$store.getters['shared/getOfferList'];
        this.selectedOffers = this.offerList.filter(item => this.defaultOffer.find(x => x === item.id)).map(item => item.id);
        this.updateOffers();
        // this.offerList.forEach(item => {
        //     if(this.defaultOffer.find(x => x === item.id)) {
        //         item['disabled'] = true;
        //     } else {
        //         item['disabled'] = false;
        //     }
        // })
    },
    methods: {
        toggleItem(item) {
              if (this.selectedOffers.includes(item)) {
                  this.selectedOffers = this.selectedOffers.filter(v => v !== item);
              } else {
                  this.selectedOffers.push(item);
              }
              this.updateOffers();
          },
          toggle() {
              if (this.selectedAllOffers) {
                  this.selectedOffers = [];
              } else {
                  this.selectedOffers = this.offerList.map(item => item.id);
              }
              this.updateOffers();
          },
        updateOffers() {
          if(!this.selectedOffers.length) {
            this.selectedOffers = this.offerList.filter(item => this.defaultOffer.find(x => x === item.id)).map(item => item.id);
          }
            this.$emit('updateOffers', this.selectedOffers);
        },
    }
  }
  </script>
  <style lang="scss">
  .v-list-item--disabled{
    opacity: 0.6 !important;
  }
  </style>
  