// src/plugins/networkStatus.js
export default {
    install(Vue) {
      Vue.prototype.$networkStatus = Vue.observable({
        isOnline: navigator.onLine,
      });
  
      // Event listeners to update network status
      window.addEventListener("online", () => {
        Vue.prototype.$networkStatus.isOnline = true;
      });
      window.addEventListener("offline", () => {
        Vue.prototype.$networkStatus.isOnline = false;
      });
    },
  };
  