import Vue from 'vue';
import axios from 'axios'

const state = {
    groupData: {},
    tagsList: [],
}

const mutations = {
    SET_GROUP_DATA(state, data) {
        Vue.set(state, 'groupData', data);
    },
    SET_TAGS_LIST(state, data) {
        Vue.set(state, 'tagsList', data);
    },
}

const actions = {
    async getTagList({ commit}, params) {
        commit('SET_LOADING', true);
        let tags = [];
        let offerId = Array.isArray(params.offerId) ? params.offerId[0] : params.offerId;
        if(params.pageType === 'vendor') {
            tags =  await axios.get(`${process.env.VUE_APP_API}/mr/taglist?offer_id=${params.offerId}&token=${params.token}&vendor_id=${params.vendor_id}`);
        } else if(params.pageType === 'LHS') {
            tags = await axios.get(`${process.env.VUE_APP_API}/mr/taglist?mr_id=${params.mrId}`);
        } else {
            tags = await axios.get(`${process.env.VUE_APP_API}/mr/taglist?offer_id=${offerId}`);
        }
        commit('SET_TAGS_LIST', tags.data.tags);
    },
    async getRequirementGroups({ commit}, params) {
        console.log('getRequirementGroups',params);
        
        let groups;
        if(params.pageType === 'lhs' || params.pageType === 'vendorCompare'){
            if(params.activeTab === 'mr_compliance') {
                groups = await axios.get(`${process.env.VUE_APP_API}/tagged/compliance/groups?mr_id=${params.mrId}`);
            } else if(params.activeTab === 'scope_of_work') {
                groups = await axios.get(`${process.env.VUE_APP_API}/tagged/sow/groups?mr_id=${params.mrId}`);
            }
        }else if(params.pageType === 'rhs'){
            if(params.activeTab === 'mr_compliance') {
                groups = await axios.get(`${process.env.VUE_APP_API}/tagged/compliance/groups?offer_id=${params.offerId}`);
            } else if(params.activeTab === 'scope_of_work') {
                groups = await axios.get(`${process.env.VUE_APP_API}/tagged/sow/groups?offer_id=${params.offerId}`);
            }
        } else if(params.pageType === 'vendor'){
            if(params.activeTab === 'mr_compliance') {
                groups = await axios.get(`${process.env.VUE_APP_API}/tagged/compliance/groups?offer_id=${params.offerId}&vendor_id=${params.vendorId}&token=${params.token}`);
            } else if(params.activeTab === 'scope_of_work') {
                groups = await axios.get(`${process.env.VUE_APP_API}/tagged/sow/groups?offer_id=${params.offerId}&vendor_id=${params.vendorId}&token=${params.token}`);
            }
        }
        console.log('groups', groups);
        let groupData = {}
        groups.data.requirement_groups.forEach(group => {
            groupData[group] = {
                groupName: group,
                status: false
            }
        });
        commit('SET_GROUP_DATA', groupData);
        return groups;
    },
    async getTechnicalSpec({ commit, state}, params) {
        console.log('getTechnicalSpec',params);
        
        let techSpec;
        if(params.pageType === 'lhs'){
            if(params.activeTab === 'mr_compliance') {
                techSpec = await axios.get(`${process.env.VUE_APP_API}/tagged/compliance/specification?mr_id=${params.mrId}&requirements_group=${params.group}`);
            } else if(params.activeTab === 'scope_of_work') {
                techSpec = await axios.get(`${process.env.VUE_APP_API}/tagged/sow/specification?mr_id=${params.mrId}&requirements_group=${params.group}`);
            }
        }else if(params.pageType === 'rhs'){
            if(params.activeTab === 'mr_compliance') {
                techSpec = await axios.get(`${process.env.VUE_APP_API}/tagged/compliance/specification?offer_id=${params.offerId}&requirements_group=${params.group}`);
            } else if(params.activeTab === 'scope_of_work') {
                techSpec = await axios.get(`${process.env.VUE_APP_API}/tagged/sow/specification?offer_id=${params.offerId}&requirements_group=${params.group}`);
            }
        }else if(params.pageType === 'vendor'){
            if(params.activeTab === 'mr_compliance') {
                techSpec = await axios.get(`${process.env.VUE_APP_API}/tagged/compliance/specification?offer_id=${params.offerId}&requirements_group=${params.group}&vendor_id=${params.vendorId}&token=${params.token}`);
            } else if(params.activeTab === 'scope_of_work') {
                techSpec = await axios.get(`${process.env.VUE_APP_API}/tagged/sow/specification?offer_id=${params.offerId}&requirements_group=${params.group}&vendor_id=${params.vendorId}&token=${params.token}`);
            }
        }else if(params.pageType === 'vendorCompare'){
            const data = {
                mr_id: params.mrId,
                requirement_group: decodeURIComponent(params.group),
                offer_id_list: params.offerId.split(','),
            };
            if(params.activeTab === 'mr_compliance') {
                techSpec = await axios.post(`${process.env.VUE_APP_API_MSC}/tagged/compliance/comparison`, data);
            } else if(params.activeTab === 'scope_of_work') {
                techSpec = await axios.post(`${process.env.VUE_APP_API_MSC}/tagged/sow/comparison`, data);
            }
        }
        let groups = JSON.parse(JSON.stringify(state.groupData))
        if(Array.isArray(techSpec.data.specification_data)) {
            Object.keys(groups).forEach(groupName => {
                if(encodeURIComponent(groupName) === params.group) {
                    console.log('techSpec', techSpec);
                    if(params.groupStatus) {
                        techSpec?.data?.specification_data?.forEach((val) => {
                            val['status'] = true;
                        })
                    }
                    groups[groupName] = {
                        groupName,
                        status: params.groupStatus,
                        techSpec: techSpec.data.specification_data
                    }
                }
            });
        }
        commit('SET_GROUP_DATA', groups);
    },
    addTechSpec({ commit}, params) {
        if(params.active_tab === 'mr_compliance') {
            return axios.post(`${process.env.VUE_APP_API}/tagged/compliance/specification`, params);
        } else if(params.active_tab === 'scope_of_work') { 
            return axios.post(`${process.env.VUE_APP_API}/tagged/sow/specification`, params);
        }
        
    },
    editTechSpec({ commit}, params) {
        if(params.active_tab === 'mr_compliance') {
            return axios.put(`${process.env.VUE_APP_API}/tagged/compliance/specification`, params);
        } else if(params.active_tab === 'scope_of_work') { 
            return axios.put(`${process.env.VUE_APP_API}/tagged/sow/specification`, params);
        }
    },
    updateStoreTechSpec({ commit, state}, params) {
        console.log('updateStoreTechSpec', state.groupData, params);
        state.groupData[params.requirements_group].techSpec.find((item) => item.row_uuid === params.row_uuid)['2'] = params.new_value;
    },
    getTimeline({ commit}, params) {
        if(params.pageType === 'lhs') {
            return axios.get(`${process.env.VUE_APP_API_MSD}/quickaction?row_uuid=${params.row_uuid}&mr_id=${params.mrId}&category=${params.pageType}&active_tab=${params.activeTab}`)
        } else if(params.pageType === 'vendor') {
            return axios.get(`${process.env.VUE_APP_API_MSD}/quickaction?row_uuid=${params.row_uuid}&offer_id=${params.offerId}&category=${params.pageType}&active_tab=${params.activeTab}&vendor_id=${params.vendorId}&token=${params.token}`)
        }
        return axios.get(`${process.env.VUE_APP_API_MSD}/quickaction?row_uuid=${params.row_uuid}&offer_id=${params.offerId}&category=${params.pageType}&active_tab=${params.activeTab}`);
    },
    createIDC({ commit}, params) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/quickaction/bulk`, params);
    },
    reOrderGroup({ commit}, params) {
        return axios.put(`${process.env.VUE_APP_API}/template/save`, params);
    },
    getVendorName({ commit}, params) {
        return axios.get(`${process.env.VUE_APP_API}/offer/vendor_check?vendor_name=${params.getVendorName}&check=vendor_name&item=${params.mrId}`);
    },
    publishMR({ commit}, params) {
        return axios.post(`${process.env.VUE_APP_API}/tbe/publish`, params);
    },
    downloadLHSFile({state},params){
        return axios.get(`${process.env.VUE_APP_API}/mr/download?mr_id=${params.id}`);
    },
    getOfferNames({state},params){
        if(params.pageType === 'vendorCompare'){
            return axios.get(`${process.env.VUE_APP_API}/offer/names?${params.id}`);
        }else{
            return axios.get(`${process.env.VUE_APP_API}/offer/names?id=${params.id}`);
        }
    },
    updateVendorResp({commit}, params) {
        if(params.active_tab === 'mr_compliance') {
            return axios.put(`${process.env.VUE_APP_API}/tagged/compliance/specification`, params);
        }
        return axios.put(`${process.env.VUE_APP_API}/tagged/sow/specification`, params);
    },
    saveInference({ commit}, params) {
        if(params.activeTab === 'mr_compliance'){
            return axios.post(`${process.env.VUE_APP_API_MSD}/compliance/inference`, params);
        }else{
            return axios.post(`${process.env.VUE_APP_API_MSD}/sow/inference`, params);
        }  
    },
    downloadTQ({ commit}, params) {
        return axios.get(`${process.env.VUE_APP_API_MSD}/tqthread/export?offer_id=${params.id}`);
    },
    getDeviationList({ commit}, params) {
        if(params.pageType === 'vendor'){
            return axios.get(`${process.env.VUE_APP_API_MSD}/deviations?offer_id=${params.offerId}&token=${params.token}&vendor_id=${params.vendorId}`);
        } 
        return axios.get(`${process.env.VUE_APP_API_MSD}/deviations?offer_id=${params.offerId}`);
    },
    updateDeviation({ commit}, params) {
        return axios.put(`${process.env.VUE_APP_API_MSD}/deviations`, params);
    },
    deleteDeviation({ commit}, params) {
        return axios.delete(`${process.env.VUE_APP_API_MSD}/deviations`, {data:params});
    },
    publishDeviation({ commit}, params) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/deviations/publish`, params);
    },
    exportDeviations({ commit}, params) {
        return axios.get(`${process.env.VUE_APP_API_MSD}/deviations/export?token=${params.token}&vendor_id=${params.vendorId}&offer_id=${params.offerId}`);
    },
    collaborationStats({ commit}, params) {
        if(params.pageType === 'vendor'){
            return axios.post(`${process.env.VUE_APP_API}/vendor/offer/stats`, params);
        }else{
            return axios.post(`${process.env.VUE_APP_API_MSD}/collaboration/stats`, params);
        }
    },
    createDeviation({ commit}, params) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/deviations`, params);
    },
    moveSpecs({ commit}, params) {
        return axios.put(`${process.env.VUE_APP_API}/arrange/lhs/tech-specs`, params);
    },
    extractDeviation({ commit}, params) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/deviations/extract`, params);
    },
    downloadSampleFormat({ commit}, params) {
        return axios.get(`${process.env.VUE_APP_API}/template/formats/download?token=${params.token}&vendor_id=${params.vendorId}&format=${params.format}`);
    },
    getFileList({ commit}, params) {
        return axios.get(`${process.env.VUE_APP_API}/offer/names?id=${params.offerId}&token=${params.token}&vendor_id=${params.vendorId}`);
    },
    blankCheck({ commit}, params) {
        return axios.post(`${process.env.VUE_APP_API}/vendor/offer/stats`, params);
        // return axios.post(`${process.env.VUE_APP_API}/offer/blank/check`, params);
    },
    publishToClient({ commit}, params) {
        return axios.post(`${process.env.VUE_APP_API}/offer/publish`, params);
    },
    tbeActionsLHS({ commit}, params) {
        return axios.put(`${process.env.VUE_APP_API}/tagged/lhs/bulk_actions`, params);
    },
    tbeActionsRHS({ commit}, params) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/tagged/rhs/bulk_actions`, params);
    },
    assinedListing({ commit}, params) {
        return axios.post(`${process.env.VUE_APP_API}/assignedlisting`, params);
    },
    publishDeviationResponse({ commit}, params){
        return axios.post(`${process.env.VUE_APP_API_MSD}/deviations/response/publish`, params);
    },
    addFollowup({ commit}, params) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/deviations/followup`, params);
    },
    offerForward({ commit}, params) {
        return axios.post(`${process.env.VUE_APP_API}/offer/publish`, params);
    },
    downloadDeviationTemplate({ commit}, params) {
        return axios.get(`${process.env.VUE_APP_API}/template/formats/download?format=deviations&vendor_id=${params.vendor_id}&token=${params.token}`);
    }
}

const getters = {
    getGroupData() {
        return state.groupData;
    },
    getTagList(state) {
        return state.tagsList;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}