<template>
  <v-app>
    <router-view/>
    <NotificationContainer/>
    <network-notification :isOnline="isOnline" />
    <scroll/>
  </v-app>
</template>

<script>
import scroll from '@/components/Reusable Components/scrollToTop';
import NotificationContainer from '@/components/NotificationContainer.vue';
import NetworkNotification from "@/components/networkNotification";
import 'vue-select/dist/vue-select.css'

export default {
  name: 'App',
  components: {
    NotificationContainer,
    NetworkNotification,
    scroll
  },
  computed: {
    isOnline() {
      return this.$networkStatus ? this.$networkStatus.isOnline : true;
    },
  },
  watch: {    

  },
  mounted(){
    console.log('route => ', this.$route);
    if (this.$route.name != 'Login' && this.$route.name != 'Login' && this.$route.name != null && this.$route.name != undefined) {
      console.log('dispatch flag');
      this.$store.dispatch('shared/getFeatureAuthToken') 
    }
  }
}

</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 25px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  // #launcher {
  //   left: 0px;
  // }

</style>
