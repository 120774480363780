<template>
<div :class="{'alt-view': pageView === 'alt-view'}">
    <div class="text-right quickactions">
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="#76D296" v-bind="attrs" v-on="on" label="Accept" @click.stop.prevent="takeQuickAction('accept')">
                    <v-icon>mdi-check-circle</v-icon>
                </v-btn>
            </template>
            <span>Accept</span>
        </v-tooltip>
        <v-menu rounded="lg" :close-on-click="false" :close-on-content-click="false" v-model="AddToTQPopout" transition="slide-x-transition" right top :offset-x="offset">
            <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn @click="toggleBodyClass('addClass', 'popovermenu')" label="Add to TQ" icon color="#FF8842" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                            <v-icon v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                        </v-btn>
                    </template>
                    <span>Add to TQ</span>
                </v-tooltip>
            </template>
            <v-card class="quickactionspoput">
                <h1>
                    <v-icon color="#FF8842">mdi-help-circle</v-icon> Add to TQ
                </h1>
                <textarea name="" v-model="quickActionCommentsTQ" @keyup="commentsTQText" rows="4" placeholder="Add your comments and click submit" class="px-2 pt-1"></textarea>
                <TagsDropdown v-if="$props.itemType != 'packages'" :key="showTagsDropdown" :piping="isPiping" :taglists="taglists" :showEvalIcon="showEvalIcon" :currentTag="currentTag" @getSelectedTags="getSelectedTag" />
                <PackagesDropdown v-else :currentTag="currentTag" @getSelectedTags="getSelectedTag"/>
                <disciplineDropdown :defaultDiscipline="defaultDiscipline" :selectionType="false" @updateDisciplines="updateDisciplines"></disciplineDropdown>
                <offerDropdown v-if="currentOfferId" :key="currentOfferId" :defaultOffer="currentOffer" :selectionType="true" :offerList="offerList" @updateOffers="updateSelectedOffer"></offerDropdown>
                <v-file-input v-model="tqAttachment" multiple chips truncate-length="15" label="Attach file(optional)" class="file-attach"></v-file-input>
                <small v-if="commentsRequiredTQ.length" class="text-danger">{{ commentsRequiredTQ }}</small>
                <v-card-actions class="px-0">
                    <v-spacer></v-spacer>
                    <v-btn elevation="0" depressed class="px-4" rounded small @click="closeQuickActionsPoput">
                        Cancel
                    </v-btn>
                    <v-btn :disabled="submitLoad" elevation="1" @click="takeQuickActionTQ('add to tq')" color="#000026" class="text-white px-4" rounded small>Submit <v-progress-circular indeterminate color="#fff" :size="15" v-if="submitLoad" style="margin-left: 10px;"></v-progress-circular>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
        <v-menu rounded="lg" :close-on-click="false" :close-on-content-click="false" v-model="IdcPopout" transition="slide-x-transition" right top>
            <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip top>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn @click="toggleBodyClass('addClass', 'popovermenu')" label="IDC (Assign)" icon color="#FDD666" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                            <v-icon v-bind="attrs" v-on="on">mdi-reply-circle</v-icon>
                        </v-btn>
                    </template>
                    <span>IDC (Assign)</span>
                </v-tooltip>
            </template>
            <v-card class="quickactionspoput">
                <h1>
                    <v-icon color="#FDD666" class="fa-flip-horizontal">mdi-reply-circle</v-icon> IDC (Assign)
                </h1>
                <disciplineDropdown :key="resetFields" @updateDisciplines="updateIDCDisciplines" :isIDC="true" class="discipline-dropdown"></disciplineDropdown>
                <small v-if="!selectedDisciplines.length && disciplineWarning" class="text-danger">Discipline Required</small>
                <textarea name="" v-model="quickActionCommentsAssign" @keyup="commentsAssignText" rows="4" placeholder="Add your comments and click submit" class="px-2 py-1 mt-3"></textarea>
                <small v-if="commentsRequiredA.length" class="text-danger">{{ commentsRequiredA }}</small>
                <TagsDropdown v-if="$props.itemType != 'packages'" :taglists="taglists" :key="!resetFields" :showEvalIcon="showEvalIcon" :piping="isPiping" :currentTag="currentTag" @getSelectedTags="getSelectedTag" />
                <PackagesDropdown v-else :currentTag="currentTag" @getSelectedTags="getSelectedTag"/>
                <v-file-input v-model="IDCAttachment" class="mb-2 file-attach" chips multiple truncate-length="15" label="Attach file(optional)"></v-file-input>
                <v-card-actions class="px-0">
                    <v-spacer></v-spacer>
                    <v-btn elevation="0" depressed class="px-4" rounded small @click="closeQuickActionsPoput">
                        Cancel
                    </v-btn>
                    <v-btn elevation="1" :disabled="submitLoad" @click="takeQuickActionAssign('assign')" color="#000026" class="text-white px-4" rounded small>Submit <v-progress-circular indeterminate color="#fff" :size="15" v-if="submitLoad" style="margin-left: 10px;"></v-progress-circular>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="#FFBEB1" v-bind="attrs" v-on="on" label="Non-Compliant" @click.stop.prevent="takeQuickAction('confirmcde')">
                    <v-icon>mdi-close-circle</v-icon>
                </v-btn>
            </template>
            <span>Non-Compliant</span>
        </v-tooltip>
        <v-tooltip top v-if="showEvalOnPage">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="#6bb6ff" v-bind="attrs" v-on="on" label="Evaluate other tags" @click="getTagForEval" v-if="showEvalIcon">
                    <v-icon>mdi-tag-multiple</v-icon>
                </v-btn>
            </template>
            <span>Evaluate other tags</span>
        </v-tooltip>
    </div>
    <div class="notification-cnt quick text-left">
        <v-alert type="success" v-if="successMessage">{{ successMessage }}</v-alert>
        <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
        <v-alert v-if="warningAlert" outlined border="left" prominent type="warning" style="width: 500px;background: #fff !important;">
            Please note that the following technical description(s) is/are not found in other packages of this bid tab. Your action will not be applied to these packages. 
            <div v-for="(l,j) in warningAlertMessage" :key="j">
                <b>{{ j }} :{{l}}</b>
            </div>
      </v-alert>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import TagsDropdown from '@/components/Reusable Components/TagsDropdown';
import PackagesDropdown from '@/components/Reusable Components/PackagesDropdown';
import disciplineDropdown from '@/components/Reusable Components/DisciplineDropdown';
import offerDropdown from '@/components/Reusable Components/OfferDropdown';
export default {
    props: ['quickData', 'vn', 'em', 'lock', 'spec', 'dragDisable', 'showEvalIcon', 'taglists', 'currentTag', 'category', 'defaultDiscipline', 'itemType','pageView'],
    data() {
        return {
            AddToTQPopout: false,
            IdcPopout: false,
            quickActionComments: '',
            quickActionCommentsAssign: '',
            quickActionCommentsTQ: '',
            on: '',
            em_ad: [],
            tagEval: false,
            cellData: {},
            updateCellData: {},
            successMessage: '',
            errorMessage: '',
            name: "tag-list",
            commentsRequiredA: '',
            assignRequired: '',
            commentsRequiredTQ: '',
            payloadAttachments: [],
            offset: false,
            inferenceRequired: false,
            tqAttachment: [],
            IDCAttachment: [],
            submitLoad: false,
            techSpecTags: [],
            showTagsDropdown: true,
            selectedDisciplines: [],
            disciplineWarning: false,
            resetFields: false,
            selectedDiscipline: [],
            offerList: [],
            selectedOffers: [],
            currentOffer: [],
            warningAlert:false,
            warningAlertMessage:{},
            disableAction: false
        }
    },
    components: {
        TagsDropdown,
        disciplineDropdown,
        offerDropdown,
        PackagesDropdown
    },
    computed: {
        showEvalOnPage() {
            if (this.$route.name == 'View TBE (RHS) – Default') {
                return true
            } else {
                return false
            }
        },
        isPiping() {
            return (this.$props.quickData.is_piping)
        },
        currentOfferId() {
            let offerId = this.$props.quickData.parent_offer_id || this.$props.quickData.offer;
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.currentOffer = [offerId];
            return offerId;
        },
    },
    watch: {
        quickData(newVal) {
            this.errorMessage = '';
            if (this.$props.itemType != 'packages' && this.$props.quickData.technical_description[3]?.length > 0) {
                this.inferenceRequired = false
            }
            this.cellData = newVal
            if (this.$props.quickData.technical_description.hasOwnProperty('offer_id')) {
                this.$props.quickData.offer = this.$props.quickData.technical_description.offer_id
            }
        },
        vn(newVal) {
            if (newVal) {
                this.vendor_name = newVal
            }
        },
    },
    mounted() {
        console.log('quickactions props',this.$props);
        this.resetFields = !this.resetFields;
    },
    destroyed() {
        this.toggleBodyClass('removeClass', 'popovermenu');
    },
    methods: {
        updateSelectedOffer(offers) {
            this.selectedOffers = offers;
        },
        updateDisciplines(selectedDisciplines) {
            if (selectedDisciplines?.length) {
                this.selectedDiscipline = [selectedDisciplines];
            } else {
                this.selectedDiscipline = [];
            }
        },
        getSelectedTag(selected) {
            if(this.$props.itemType == 'packages'){
              this.techSpecTags = selected.map(pack => pack.sub_package_uuid);
            }else{
              this.techSpecTags = selected.map(tag => tag.tag_uuid);
            }
        },
        onClickOutside() {
            this.toggleBodyClass('addClass', 'popovermenu');
        },
        toggleBodyClass(addRemoveClass, className) {
            const el = document.body;
            if (addRemoveClass === 'addClass') {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
        updateIDCDisciplines(selectedDisciplines) {
            this.selectedDisciplines = selectedDisciplines;
        },
        commentsAssignText() {
            if (this.quickActionCommentsAssign.length < 2) {
                this.commentsRequiredA = 'Comments Required'
            } else {
                this.commentsRequiredA = ''
            }
        },
        commentsTQText() {
            if (this.quickActionCommentsTQ.length < 2) {
                this.commentsRequiredTQ = 'Comments Required'
            } else {
                this.commentsRequiredTQ = ''
            }
        },
        close(val, msg) {
            this.tagEval = false
            if (val == 'tag') {
                this.$emit('tag-close', this.cellData, msg)
            }
            this.showPopup = false

        },
        closeQuickActionsPoput() {
            this.AddToTQPopout = false
            this.IdcPopout = false
            this.quickActionComments = ''
            this.selectedDisciplines = [],
            this.selectedOffers = [],
            this.commentsRequiredA = ''
            this.assignRequired = ''
            this.quickActionCommentsAssign = ''
            this.quickActionCommentsTQ = ''
            this.commentsRequiredTQ = ''
            this.tqAttachment = [],
            this.IDCAttachment = [],
            this.payloadAttachments = []
            this.techSpecTags = []
            this.showTagsDropdown = !this.showTagsDropdown
            this.submitLoad = false
            this.resetFields = !this.resetFields;
            this.disciplineWarning = false
            this.toggleBodyClass('removeClass', 'popovermenu');
        },
        onSelect() {

        },
        downloadFile() {

        },
        takeQuickActionTQ(actionType) {
            if (this.quickActionCommentsTQ.trim().length < 2 && this.quickActionCommentsTQ.trim() === '') {
                this.commentsRequiredTQ = 'Comments Required'
            } else if (this.$props.quickData.technical_description['2__collab'] || this.$props.quickData.technical_description['collab']) {
                this.quickActionComments = this.quickActionCommentsTQ
                this.handleFileUpload(actionType, this.tqAttachment)
                this.submitLoad = true
            } else {
                this.quickActionComments = this.quickActionCommentsTQ
                this.handleFileUpload(actionType, this.tqAttachment);
                this.submitLoad = true
            }
        },
        handleFileUpload(actionType, attachment) {
            console.log('this.$props.quickData.offer',this.$props.quickData);
            
            if (attachment.length > 0) {
                attachment.forEach((files) => {
                    var data = new FormData();
                    data.append('file', files)
                    // const queryParams = actionType === 'assign' ? `type=idc&mr_id=${this.$route.query.item_id || this.$route.query.itemId}&offer_id=${this.quickData.offer}` : `type=tq&offer_id=${this.$props.quickData.offer}`
                    const queryParams = (actionType === 'assign' && this.itemType !== 'packages') 
                        ? `type=idc&mr_id=${this.$route.query.item_id || this.$route.query.itemId}&offer_id=${this.quickData.offer}`
                        : actionType === 'assign' && this.itemType === 'packages'
                        ? `type=idc&package_id=${this.$route.query.package_id || this.$route.query.package_id}&offer_id=${this.quickData.offer}`
                        : `type=tq&offer_id=${this.$props.quickData.offer || this.$props.quickData.parent_offer_id}`;
                    axios.post(`${process.env.VUE_APP_API}/file/upload?${queryParams}`, data, {
                            headers: {
                                'Access-Control-Allow-Origin': '*'
                            },
                            processData: false,
                            contentType: false,
                        })
                        .then(response => {
                            this.payloadAttachments.push({
                                file: files.name,
                                file_key: response.data.file
                            })
                            if (attachment.length == this.payloadAttachments.length) {
                                this.collabActions(actionType)
                            }
                        })
                        .catch(err => {
                            this.tqAttachment = [];
                            this.selectedDisciplines = [];
                            this.payloadAttachments = []
                            this.closeQuickActionsPoput()
                            this.submitLoad = false
                            this.errorMessage = err.response.data.message
                            setTimeout(() => {
                                this.errorMessage = ''
                            }, 3000)
                        });
                })
            } else {
                this.collabActions(actionType)
            }
        },
        takeQuickActionAssign(actionType) {
            if (!this.selectedDisciplines.length) {
                this.disciplineWarning = true;
            } else if (this.quickActionCommentsAssign.trim().length < 2 && this.quickActionCommentsAssign.trim() === '') {
                this.commentsRequiredA = 'Comments Required'
                this.disciplineWarning = true;
            } else {
                this.quickActionComments = this.quickActionCommentsAssign;
                this.submitLoad = true;
                this.handleFileUpload(actionType, this.IDCAttachment);
            }
        },
        takeQuickAction(actionType) {
            // console.log('takeQuickAction',this.$props.quickData.technical_description[3]);
            console.log('takeQuickAction,cellData',this.cellData);
            console.log('takeQuickAction,quickData',this.quickData);
            this.disableAction = true;
            this.errorMessage = ''
            if (actionType == 'confirmcde' || actionType == 'accept') {
                this.quickActionComments = 'No comment'
            }
            if (!this.$props.quickData.showEval) {
                if (this.$props.quickData.technical_description[3] == '' || this.$props.quickData.reInference == '' || (this.$props.quickData.technical_description.type === 'inference' && this.$props.quickData.technical_description.inference == '')) {
                    this.errorMessage = 'Inference cell cannot be empty'
                } else {
                    this.collabActions(actionType)
                }
            } else {
                this.collabActions(actionType)
            }

        },
        collabActions(actionType) {
            console.log('this.$props', this.$props,this.cellData);
            this.updateCellData = this.cellData
            let payload = {}
            let msg = ''
            msg = 'create_line'
            if (this.$props.quickData.technical_description.hasOwnProperty('offer_id')) {
                this.$props.quickData.offer = this.$props.quickData.technical_description.offer_id || this.$props.quickData.parent_offer_id
            }
            payload = {
                offer_id: this.$props.quickData.parent_offer_id || this.$props.quickData.offer,
                row_uuid: this.$props.quickData.technical_description.inference_row_uuid || this.$props.quickData.technical_description.row_uuid,
                tech_spec: this.pageView === 'alt-view' ? this.$props.quickData.technical_description['1'] : this.$props.quickData.technical_description['0'],
                action: actionType,
                comment: this.quickActionComments,
                attachments: this.payloadAttachments,
                disciplines: actionType === 'assign' ? this.selectedDisciplines : this.selectedDiscipline,
                category: this.category,
                req_group: this.quickData.name,
            }
            if (this.cellData.category_type == 'packages' || this.itemType == 'packages') {
                payload.sub_package_ids = this.techSpecTags && this.techSpecTags.length > 0 ? this.techSpecTags : [this.currentTag?.sub_package_uuid];
                // payload.package_id = this.$route.query.package_id;
            } else {
                payload.tag_ids =  this.techSpecTags && this.techSpecTags.length > 0 ? [...this.techSpecTags, this.currentTag?.tag_uuid] : [this.currentTag?.tag_uuid];
                // payload.mr_id = this.$route.query.mr_id;
            }
            if (actionType == 'add to tq') {
                payload.offer_id = this.selectedOffers;
                payload['default_id'] = this.currentOfferId;
                // if (this.selectedOffers.length) {
                //     msg = 'multi_vendor_tq';
                // }
            }
            axios({
                url: `${process.env.VUE_APP_API_MSD}/quickaction/bulk`,
                method: 'post',
                data: [payload]
            }).then(response => {
                console.log('collabActions data actionType', actionType, this.updateCellData, response.data.data[0].action);
                if (typeof response.data.missed_data === 'object' && 
                    Object.keys(response.data.missed_data).some(key => 
                    Array.isArray(response.data.missed_data[key]) && response.data.missed_data[key].length > 0)) {
                    this.warningAlert = true
                    this.warningAlertMessage = response.data.missed_data
                }  
                this.closeQuickActionsPoput();
                this.disableAction = false;
                this.errorMessage = ''
                if(this.$props.itemType == 'packages'){
                  this.updateCellData.technical_description.action = response.data.data[0].action
                  if(this.$route.name !== 'Offer Evaluation Packages'){
                    this.updateCellData.technical_description['2__collab'] = {
                        status: response.data.data[0].action,
                        view: 'owner',
                    }
                  }
                  this.$emit('updateQSpec', response, msg, this.updateCellData);
                }
                else if(this.pageView === 'alt-view'){
                    this.$emit('updateQSpec', response, msg, this.updateCellData)                  
                }else{
                    this.updateCellData.technical_description['2__collab'] = {
                        status: response.data.data[0].action,
                        view: 'owner',
                    }
                    this.$emit('updateQSpec', response, msg, this.updateCellData);
                }
                this.successMessage = 'Action saved'
                setTimeout(() => {
                    this.successMessage = ''
                    this.warningAlert = false
                }, 5000)
            }).catch((err) => {
                console.log('err',err);
                this.errorMessage = err.response.data.message
                setTimeout(() => {
                    this.errorMessage = ''
                }, 3000)
            })
        },
        getTagForEval() {
            this.cellData = this.$props.quickData
            this.$emit('showTagEval', this.cellData)
            // this.$emit('tagListPopupActive')
        },
    }
}
</script>

<style lang="scss">
.file-attach {
    max-height: 66px;
    overflow: auto;
}
.quickactionspoput .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none;
    border: 1px solid #ccc;
    min-height: 35px;
}

.quickactionspoput .v-text-field--filled.v-input--dense.v-text-field--single-line .v-label {
    top: 7px;
}

.popovermenu .offer-evaluation,
.popovermenu .detailedComparison {
    pointer-events: none;
}

.popovermenu .detailedComparison:after,
.popovermenu .offer-evaluation:after {
    pointer-events: stroke;
    content: '';
    background: #00000000;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}
</style>
<style lang="scss" scoped>
.alt-view{
    display: inline-block;
    .quickactions {
        .v-btn--icon {
            height: 20px;
            width: 20px;

            .v-icon {
                font-size: 20px;
            }
        }
    }
}
.v-tooltip__content {
    z-index: 999999999 !important;
}

.quickactions {
    .v-btn--icon {
        height: 30px;
        width: 30px;

        .v-icon {
            text-indent: unset;
        }
    }
}

.discipline-dropdown {
    max-height: 35px;
}

.quickactionspoput {
    padding: 15px 20px 5px 20px;
    width: 320px;

    h1 {
        font-size: 14px;
        font-weight: bold;

        .v-icon {
            font-size: 22px;
        }
    }

    textarea {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 3px;
    }

    select {
        box-shadow: none;
        border: 1px solid #ccc;
        min-height: 35px;
        border-radius: 3px;
        /*color: #8c8c8c;*/
        padding: 0 5px;
        width: 100%;
        -moz-appearance: none;
        -webkit-appearance: auto;
        margin-bottom: 10px;
    }

    .v-btn {
        text-transform: capitalize;
        letter-spacing: unset;
    }

    .v-input {
        .v-input__control {
            .v-input__slot {
                box-shadow: none !important;
            }
        }
    }
}
</style>
