import Vue from 'vue';
import axios from 'axios'
import { getUnit } from 'gsap';

const state = {
    asdasd:{},
    sharedData: {}
}

const mutations = {
    SET_TAGS_TECH_DATA (state, data) {
        Vue.set(state, 'tabularData', data);
    },
    SET_SHARED_DATA(state, data) {
        state.sharedData = data;
    }
}

const actions = {
    getMRCategory () {
        return axios.get(`${process.env.VUE_APP_API}/categories`);
    },
    deleteTechDesc ({commit}, params){
        return axios.put(`${process.env.VUE_APP_API}/template/manage/description/delete`, params);
    },
    shiftTechDesc ({commit}, params){
        return axios.put(`${process.env.VUE_APP_API}/template/shift`, params);
    },
    templateSaveUpdates ({commit}, params){
        return axios.put(`${process.env.VUE_APP_API}/template/save`, params);
    },
    getUnits({commit}, params) {
        return axios.get(`${process.env.VUE_APP_API}/specification/units?template_id=${params.template_id}&tech_spec=${params.tech_spec}`);
    },
    getTempTechnicalDescription({commit}, params) {
        return axios.get(`${process.env.VUE_APP_API}/template/specification?template_id=${params.template_id}&group=${params.requirement_group}&active_tab=${params.active_tab}`);
    },
    getTempRequirementGroup({commit}, params) {
        return axios.get(`${process.env.VUE_APP_API}/template/requirementgroups?template_id=${params.template_id}&active_tab=${params.active_tab}`);
    },
    editTechDesc ({commit}, params){
        return axios.put(`${process.env.VUE_APP_API}/template/manage/description`, params);
    },
    checkTechSpec({commit}, params) {
        return axios.get(`${process.env.VUE_APP_API}/specification/check?template_id=${params.template_id}&tech_spec=${params.tech_spec}&group=${params.requirement_group}&active_tab=${params.active_tab}`);
    },
    getTechspecSuggestions({commit}, params) {
        return axios.get(`${process.env.VUE_APP_API}/template/suggest?template_id=${params.template_id}&item=${params.item_id}&group=${params.requirement_group}&active_tab=${params.active_tab}`);
    },
    addTechDesc ({commit}, params){
        return axios.post(`${process.env.VUE_APP_API}/template/manage/description`, params);
    },
    templateSave ({commit}, params){
        return axios.post(`${process.env.VUE_APP_API}/template/save`, params);
    },
    getRequirementsGroups({commit}, params) {
        return axios.get(`${process.env.VUE_APP_API}/template/requirementgroups?template_id=${params.template_id}&item_id=${params.item_id}&active_tab=${params.active_tab}`);
    },
    templateExtract ({commit}, params){
        return axios.post(`${process.env.VUE_APP_API}/template/file/extract`, params);
    },
    uploadTemplateFile({commit}, params) {
        let data = new FormData();
        data.append('file', params)
        return axios.post(`${process.env.VUE_APP_API}/file/upload?type=manage_template`, data, {
            headers: {
                'Access-Control-Allow-Origin': '*'
            },
            processData: false,
            contentType: false,
        })
    },
    getTemplateFormats({commit}, params) {
        return axios.get(`${process.env.VUE_APP_API}/template/formats/download?format=${params}`);
    },
    deleteItemConfirm({commit}, params) {
        return axios.delete(`${process.env.VUE_APP_API}/template/single?template_id=${params.id}`);
    },
    getTemplateLIst({commit}, params) {
        return axios.get(`${process.env.VUE_APP_API}/template/list?item=${params.item}`);
    },
    getItemTypes({commit}, params) {
        return axios.get(`${process.env.VUE_APP_API}/item/types?category=${params}`);
    },
    downloadTemp({commit}, params) {
        return axios.get(`${process.env.VUE_APP_API_MSC}/template/export?template_id=${params.id}`);
    },
    publishTemplate({commit}, params) {
        return axios.get(`${process.env.VUE_APP_API}/template/publish?template_id=${params.id}`);
    },
    resetState({ commit }) {
        commit('RESET_STATE');
    }
}

const getters = {
    isLoading(state) {
        return state.loading;
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}