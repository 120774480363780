import Vue from 'vue';
import axios from 'axios'

const state = {
    bulkData: {},
    groupSpecs: [],
    deviationList: [],
    identsList: {}
}

const mutations = {
    SET_BULK_DATA (state, bulkData) {
        Vue.set(state, 'bulkData', bulkData);
    },
    SET_COMPLIANCE_DATA (state, data) {
        Vue.set(state, 'groupSpecs', data);
    },
    SET_DEVIATION_DATA(state, data) {
        Vue.set(state, 'deviationList', data);
    },
    SET_IDENTS_UUID(state, data) {
        Vue.set(state, 'identsList', data);
    }
}

const actions = {
    async getBulkData({ commit, dispatch }, params) {
        await dispatch('getIdentList', params).then(async (resp) => {
            commit('SET_IDENTS_UUID', resp.data.idents_data);
        });
        let bulkData;
        if(params.pageType === 'vendor') {
            bulkData = await axios.get(`${process.env.VUE_APP_API}/bulk/tabulation?offer_id=${params.offerId}&vendor_id=${params.vendor_id}&token=${params.token}`);
        } else if(params.pageType === 'offer') {
            bulkData = await axios.get(`${process.env.VUE_APP_API}/bulk/tabulation?offer_id=${params.offerId}`);
        } else {
            bulkData = await axios.get(`${process.env.VUE_APP_API}/bulk/tabulation?mr_id=${params.mrId}`);
        }
        let structuredData = {}
        bulkData.data.specification_data.forEach((item) => {
            structuredData[Object.keys(item)[0]] = Object.values(item)[0];
        });
            commit('SET_BULK_DATA', structuredData);
        return bulkData;
    },
    async loadMoreData({ state, commit }, params) {
        let structuredData;
            let bulkData;
            structuredData = JSON.parse(JSON.stringify(state.bulkData));
            let pageNo = (Math.ceil(Object.keys(structuredData).length / 25)) + 1;
            let lodType = params.type === 'page' ? `page=${pageNo}` : 'show_all=True';
            if(params.pageType === 'vendor') {
                bulkData = await axios.get(`${process.env.VUE_APP_API}/bulk/tabulation?offer_id=${params.offerId}&vendor_id=${params.vendor_id}&token=${params.token}&${lodType}${params.filter}`);
            } else if(params.pageType === 'offer') {
                bulkData = await axios.get(`${process.env.VUE_APP_API}/bulk/tabulation?offer_id=${params.offerId}&${lodType}${params.filter}`);
            } else {
                bulkData = await axios.get(`${process.env.VUE_APP_API}/bulk/tabulation?mr_id=${params.mrId}&${lodType}${params.filter}`);
            }
            bulkData.data.specification_data.forEach((item) => {
                structuredData[Object.keys(item)[0]] = Object.values(item)[0];
                // structuredData[Object.keys(item)[0]]['tag_uuid'] = resp.data.idents_data[Object.keys(item)[0]];
                // console.log('itemsss', structuredData[Object.keys(item)[0]], '1111', resp.data.idents_data[Object.keys(item)[0]]);
            });
        commit('SET_BULK_DATA', structuredData);
    },
    async getNextRecords({ state, commit }, params) {
        let structuredData = {};
        let bulkData;
        // let loadType = params.type === 'page' ? `page=${params.pageNo}` : 'show_all=True'
        // if(params.pageType === 'vendor') {
        //     bulkData = await axios.get(`${process.env.VUE_APP_API}/bulk/tabulation?offer_id=${params.offerId}&vendor_id=${params.vendor_id}&token=${params.token}&${loadType}${params.filter}`);
        // } else if(params.pageType === 'offer') {
        //     bulkData = await axios.get(`${process.env.VUE_APP_API}/bulk/tabulation?offer_id=${params.offerId}&${loadType}${params.filter}`);
        // } else {
        //     bulkData = await axios.get(`${process.env.VUE_APP_API}/bulk/tabulation?mr_id=${params.mrId}&${loadType}${params.filter}`);
        // }
        bulkData = await axios.post(`${process.env.VUE_APP_API}/bulk/tabulation`, params);
        bulkData.data.specification_data.forEach((item) => {
            structuredData[Object.keys(item)[0]] = Object.values(item)[0];
        });
        commit('SET_BULK_DATA', structuredData);
    },
    async getFilteredData({ state, commit }, params) {
        let bulkData = await axios.post(`${process.env.VUE_APP_API}/bulk/tabulation`, params);
        let structuredData = {};
        bulkData.data.specification_data.forEach((item) => {
            structuredData[Object.keys(item)[0]] = Object.values(item)[0];
        });
        commit('SET_BULK_DATA', structuredData);
        return bulkData.data;
    },
    async getRequirementGroups ({ commit }, param) {
        let groups =[];
        if(param.pageType === 'vendor') {
            groups = await axios.get(`${process.env.VUE_APP_API}/bulk/compliance/groups?offer_id=${param.offerId}&vendor_id=${param.vendor_id}&token=${param.token}`);
        } else if(param.pageType === 'offer') {
            groups = await axios.get(`${process.env.VUE_APP_API}/bulk/compliance/groups?offer_id=${param.offerId}`);
        } else {
            groups = await axios.get(`${process.env.VUE_APP_API}/bulk/compliance/groups?mr_id=${param.mrId}`);
        }
        let groupSpecs = [];
        await groups.data.requirement_groups.forEach((item) => {
            let obj = {};
            obj[item] = { technical_specifications: [] }
            groupSpecs.push(obj);
        });
        commit('SET_COMPLIANCE_DATA', groupSpecs);
        return groups.data;
    },
    getComplianceRequirementGroups({ commit }, mrId) {
        return axios.get(`${process.env.VUE_APP_API}/bulk/compliance/groups?mr_id=${mrId}`);
    },
    async getTechnicalSpecification({ commit }, param) {
        let techSpec;
        if(param.pageType === 'vendor') {
            techSpec = await axios.get(`${process.env.VUE_APP_API}/bulk/compliance/specification?offer_id=${param.offerId}&requirements_group=${encodeURIComponent(param.requirementGroup)}&vendor_id=${param.vendor_id}&token=${param.token}`)
        } else if(param.pageType === 'offer') {
            techSpec = await axios.get(`${process.env.VUE_APP_API}/bulk/compliance/specification?offer_id=${param.offerId}&requirements_group=${encodeURIComponent(param.requirementGroup)}`);
        } else {
            techSpec = await axios.get(`${process.env.VUE_APP_API}/bulk/compliance/specification?mr_id=${param.mrId}&requirements_group=${encodeURIComponent(param.requirementGroup)}`)
        }
        let groupSpecs = state.groupSpecs;
        groupSpecs.forEach((item) => {
            if(Object.keys(item)[0] === param.requirementGroup) {
                item[param.requirementGroup].technical_specifications = techSpec.data.specification_data
            }
        });
        commit('SET_COMPLIANCE_DATA', groupSpecs);
    },
    async getDeviationList({ commit }, params) {
        let list;
        if(params.pageType === 'vendor') {
            list = await axios.get(`${process.env.VUE_APP_API_MSD}/deviations?offer_id=${params.offerId}&token=${params.token}&vendor_id=${params.vendor_id}&category=${params.category}`);
        } else {
            list = await axios.get(`${process.env.VUE_APP_API_MSD}/deviations?offer_id=${params.offerId}&category=${params.category}`);
        }
        commit('SET_DEVIATION_DATA', list.data.deviations);
    },
    async getIdentList({ commit }, params) {
        if(params.pageType === 'vendor') {
            return await axios.get(`${process.env.VUE_APP_API}/idents/list?offer_id=${params.offerId}&token=${params.token}&vendor_id=${params.vendor_id}`);
        } else if(params.pageType === 'offer') {
            return await axios.get(`${process.env.VUE_APP_API}/idents/list?offer_id=${params.offerId}`);
        } else {
            return await axios.get(`${process.env.VUE_APP_API}/idents/list?mr_id=${params.mrId}`);
        }
    },
    techSpecList({ commit }, params) {
        return [ {tag: 'Valve Type', id: 101}, {tag: 'Valve Material', id: 102}, {tag: 'Class', id: 103}, {tag: 'Flange Face', id: 104}, 
        {tag: 'Flange Body Type', id: 105}, {tag: 'Flange - Design', id: 106}, {tag: 'Offset Type', id: 107}, {tag: 'Size (NPS)', id: 108}, {tag: 'Packing', id: 109}]
    },
    getItemUUID({ commit }, params) {
        return axios.post(`${process.env.VUE_APP_API}/bulk/metadata`, params);
    },
    addFollowup({ commit }, params) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/deviations/followup`, params);
    },
    async createDeviation ({ commit }, params) {
        await axios.post(`${process.env.VUE_APP_API_MSD}/deviations`, params);
    },
    updateItemStatus({ commit }, params) {
        axios.put(`${process.env.VUE_APP_API}/bulk/tabulation`, params);
    },
    updateDeviation({ commit }, params) {
        return axios.put(`${process.env.VUE_APP_API_MSD}/deviations`, params);
    },
    deleteDeviation({ commit }, params) {
        return axios.delete(`${process.env.VUE_APP_API_MSD}/deviations`, {data:params});
    },
    getOfferStats({ commit }, params) {
        return axios.post(`${process.env.VUE_APP_API}/vendor/offer/stats`, params);
    },
    publishDeviation({ commit }, params) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/deviations/publish`, params);
    },
    async getComplianceTimeline({commit}, param) {
        return await axios.get(`${process.env.VUE_APP_API_MSD}/bulk/timeline?row_uuid=${param.row_uuid}&mr_id=${param.mr_id}&category=${param.category}`);
    },
    async getDeviationCount({ commit }, params) {
        if(params.type === 'vendor'){
            return await axios.get(`${process.env.VUE_APP_API_MSD}/deviations/count?token=${params.token}&vendor_id=${params.vendor_id}&offer_id=${params.offerId}`);
        } else {
            return await axios.get(`${process.env.VUE_APP_API_MSD}/deviations/count?offer_id=${params.offerId}`);
        }
    },
    exportTBE({commit}, mrId) {
        return axios.get(`${process.env.VUE_APP_API}/bulk/timeline/export?mr_id=${mrId}`)
    },
    exportDeviation({ commit }, params) {
        if(params.pageType === 'vendor') {
            return axios.get(`${process.env.VUE_APP_API_MSD}/deviations/export?token=${params.token}&vendor_id=${params.vendor_id}&offer_id=${params.offer_id}`);
        } else {
            return axios.get(`${process.env.VUE_APP_API_MSD}/deviations/export?offer_id=${params.offer_id}`);
        }
    },
    enableBidtab({ commit }, params) {
        return axios.post(`${process.env.VUE_APP_API}/bulk/create/mr`, params);
    },
    downloadTBE({ commit }, params) {
        return axios.get(`${process.env.VUE_APP_API_MSC}/offer/comparison/export?${params}`);
    },
    lhsBulkUpdate({ commit }, params) {
        return axios.put(`${process.env.VUE_APP_API}/bulk/compliance/bulk_actions`, params);
    },
    async getUniqueSpecValues({ commit }, params) {
        return await axios.get(`${process.env.VUE_APP_API}/bulk/tabulation/unique?${params}`);
    },
    publishDeviationResponse({ commit }, params) {
        return axios.post(`${process.env.VUE_APP_API_MSD}/deviations/response/publish`, params);
    },
    async publishMR({ commit }, params) {
        return await axios.post(`${process.env.VUE_APP_API}/mr/publish`, params);
    },
    publishTBE({ commit }, params) {
        return axios.post(`${process.env.VUE_APP_API}/offer/publish`, params);
    },
    updateSpecification({ commit }, params) {
        return axios.put(`${process.env.VUE_APP_API}/bulk/compliance/specification`, params);
    },
    addTechSpec({ commit }, params) {
        return axios.post(`${process.env.VUE_APP_API}/bulk/compliance/specification`, params);
    },
    checkTechSpec({ commit }, params) {
        return axios.get(`${process.env.VUE_APP_API}/specification/check?mr_id=${params.mrId}&tech_spec=${params.technicalDescription}&group=${encodeURIComponent(params.requirementGroup)}`);
    },
    updateVendorResp({ commit }, params) {
        return axios.put(`${process.env.VUE_APP_API}/bulk/compliance/specification`, params);
    },
    getColumnInfo({ commit }, params) {
        let queryParams = '';
        if(params.pageType === 'vendor') {
            queryParams = `offer_id=${params.offerId}&token=${params.token}&vendor_id=${params.vendor_id}`;
        } else if(params.pageType === 'offer') {
            queryParams = `offer_id=${params.offerId}`
        } else {
            queryParams = `mr_id=${params.mrId}`
        }
        return axios.get(`${process.env.VUE_APP_API}/bulk/tabulation/columns?${queryParams}`);
    },
    createColumn({ commit }, params) {
        return axios.post(`${process.env.VUE_APP_API}/bulk/tabulation/columns`, params)
    },
    updateColumnData({ commit }, params) {
        return axios.put(`${process.env.VUE_APP_API}/bulk/tabulation/vendor_edit`, params);
    },
    markColumnEditable({ commit }, params) {
        return axios.put(`${process.env.VUE_APP_API}/bulk/tabulation/columns`, params);
    },
    addColumn({ commit }, params) {
        return axios.post(`${process.env.VUE_APP_API}/bulk/tabulation/columns`, params)
    },
    deleteColumn({ commit }, params) {
        return axios.delete(`${process.env.VUE_APP_API}/bulk/tabulation/columns`, {
            data: params
        })
    },

}

const getters = {
    getBulkData() {
        return state.bulkData;
    },
    getGroupSpecs() {
        return state.groupSpecs;
    },
    getDeviationList() {
        return state.deviationList;
    },
    getIdentUUID: (state) => (identId) => {
        return state.identsList[identId];
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}