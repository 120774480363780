import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import Vuelidate from 'vuelidate'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Donut from 'vue-css-donut-chart'
import 'vue-css-donut-chart/dist/vcdonut.css'
import VueBreadcrumbs from 'vue-breadcrumbs'
import axios from 'axios'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import networkStatus from './plugins/networkStatus'; // to check networ status
import VueStickyDirective from "@renatodeleao/vue-sticky-directive"
import vSelect from 'vue-select'
import panZoom from 'vue-panzoom'
import Viewer from 'v-viewer'
import VScrollSync from 'v-scroll-sync';
import QuickActions from '@/components/Reusable Components/QuickActions'
import BulkQuickActions from '@/components/Reusable Components/BulkQuickActions'
import '@babel/polyfill'
export const EventBus = new Vue();
import VueCookie from 'vue-cookie';

Vue.use(VueBreadcrumbs, {
    template: '<nav class="breadcrumb" v-if="$breadcrumbs.length"> ' +
        '<router-link class="breadcrumb-item" v-for="(crumb, key) in $breadcrumbs" :to="linkProp(crumb)" :key="key">{{ crumb | crumbText }}</router-link> ' +
        '</nav>'
});
Vue.use(VueCookie);
Vue.use(VScrollSync)
Vue.use(Viewer)
library.add(fas)
Vue.use(panZoom);
Vue.component('v-select', vSelect)
Vue.component("QuickActions", QuickActions)
Vue.component("BulkQuickActions", BulkQuickActions)
Vue.use(networkStatus)
Vue.use(VueStickyDirective);
Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(Vuelidate)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.prototype.$log = console.log.bind(console)
Vue.use(Donut)
Vue.use(VueBreadcrumbs);
Vue.use(require('vue-moment'));

new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  created () {
    const storedData = localStorage.getItem('user');
    const parsedData = JSON.parse(storedData);
    if(this.$cookie.get('mm_auth_key')){
      parsedData['jwt'] = this.$cookie.get('mm_auth_key');
    }
    if (parsedData) {
      this.$store.commit('auth/SET_USER_DATA', parsedData)
    }
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401) {
            this.$store.dispatch('auth/logout')
        }
        // if (error.response.message = 'Signature expired. Please try again') {
        //   alert("Signature expired. Please try again")
        //   this.$store.commit('auth/CLEAR_USER_DATA')
        //   // router.replace({ path: '/login' })
        // }
        return Promise.reject(error)
      }
    )
  },
  icons,
  template: '<App/>',
  components: {
    App
  }
})

// globally configured texts
Vue.prototype.$Bidtab = 'Bid tab'
Vue.prototype.$ViewBidtablisting = 'View Bid tab listing'